export const ADMIN_ROLES = {
  ADMIN: 'administrator',
  OWNER: 'owner',
  SECURITY: 'security',
  ADMIN_WITH_SECURITY_ACCESS: 'administrator_security',
};

const ROLE_NAMES = {
  [ADMIN_ROLES.ADMIN]: 'Administrator',
  [ADMIN_ROLES.OWNER]: 'Owner',
  [ADMIN_ROLES.SECURITY]: 'Security',
  [ADMIN_ROLES.ADMIN_WITH_SECURITY_ACCESS]: 'Administrator with Security Access',
};

export const getRoleName = (role) => ROLE_NAMES[role];

export const getAvailableRolesToSelect = (isSuperuser) => {
  const availableRoles = [ADMIN_ROLES.ADMIN, ADMIN_ROLES.SECURITY, ADMIN_ROLES.ADMIN_WITH_SECURITY_ACCESS];
  if (isSuperuser) {
    availableRoles.push(ADMIN_ROLES.OWNER);
  }
  return availableRoles;
};

export const isAdmin = (role) => role === ADMIN_ROLES.ADMIN;
export const isOwner = (role) => role === ADMIN_ROLES.OWNER;
export const isAdminWithSecurityAccess = (role) => role === ADMIN_ROLES.ADMIN_WITH_SECURITY_ACCESS;
export const isSecurity = (role) => role === ADMIN_ROLES.SECURITY;

export const hasNewUpdatesBadge = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);

export const hasEditCompanyPermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);

export const hasCompanyAdminsListPermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
export const hasCompanyAdminsCreatePermission = (role) => isOwner(role);
export const hasCompanyAdminsDeletePermission = (role) => isOwner(role);
export const hasCompanyAdminsEditPermission = (role) => isOwner(role);

export const hasScheduleListPermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);

export const hasAccessLevelsEditPermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
export const hasAccessLevelsCreatePermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
export const hasAccessLevelsDeletePermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);

export const hasBuildingsEditPermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
export const hasBuildingsCreatePermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
export const hasBuildingsDeletePermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);

export const hasDoorsCreatePermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
export const hasDoorsEditPermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
export const hasDoorsDeletePermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);

export const hasTenantsCreatePermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
export const hasTenantsEditPermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
export const hasTenantsDeletePermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);

export const hasVisitorCodesCreatePermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
export const hasVisitorCodesEditPermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
export const hasVisitorCodesDeletePermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);

export const hasVUnitsCreatePermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
export const hasVUnitsEditPermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
export const hasVUnitsDeletePermission = (role) => isAdmin(role) || isOwner(role) || isAdminWithSecurityAccess(role);
