import React from 'react';
import { Breadcrumb } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getCompanyName } from 'util/getCompanyName';

import IntlMessages from '../util/IntlMessages';

const companyName = getCompanyName();

class ForgotPasswordDone extends React.Component {

  render() {
    return (
      <div className="app-wrapper">
        <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
          <h2 className="title mb-3 mb-sm-0">
            <IntlMessages id="pages.commonHeader" values={{ companyName }} />
          </h2>
          <Breadcrumb className="mb-0" tag="nav">
            <Link className="jr-list-link" to="/signin/"><IntlMessages id="appModule.forgotPasswordDone.home" /></Link>
          </Breadcrumb>
        </div>
        <div className="d-flex justify-content-center">
          <h1><IntlMessages id="appModule.forgotPasswordDone.header" /></h1>
        </div>
        <div className="d-flex justify-content-center">
          <h4><IntlMessages id="appModule.forgotPasswordDone.description" /></h4>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordDone;
