import React from 'react';
import { Formik } from 'formik';
import { NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import { delay } from 'lodash';
import IntlMessages from 'util/IntlMessages';
import LangSwitch from '../../components/LangSwitch/LangSwitch';
import s from './PasswordRecovery.module.css';

import { PasswordRecoverySchema } from '../validationSchemas';
import { Form } from './form';
import CompanyLogo from '../../components/CompanyLogo/CompanyLogo';

class PasswordConfirm extends React.Component {
  constructor() {
    super();
    this.state = {
      password: '',
      passwordConfirm: '',
      showPassword: false,
      showPasswordConfirm: false,
    };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      delay(() => { this.props.hideMessage(); }, 3000);
    }
  }

  submitValues = ({ password, passwordConfirm }) => {
    this.props.submitValues(password, passwordConfirm);
  };

  render() {
    const {
      password, passwordConfirm, showPassword, showPasswordConfirm,
    } = this.state;
    const {
      showMessage, loader, alertMessage, passwordType,
    } = this.props;
    const values = {
      password, passwordConfirm, showPassword, showPasswordConfirm,
    };
    const title = <IntlMessages id="containersPass.confirmation" values={{ passwordType }} />;

    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <CompanyLogo big />
          </div>

          <div className="app-login-content">
            <div className={s.langSwitch}>
              <LangSwitch shouldSyncWithBackend={false} />
            </div>
            <div className="app-login-header">
              <h1>{title}</h1>
            </div>

            <div className="mb-4">
              <h2><IntlMessages id="appModule.ResetPassword.header" /></h2>
            </div>

            <div className="mb-4">
              <h4><IntlMessages id="appModule.ResetPassword.description" /></h4>
            </div>

            <div className="app-login-form">
              <Formik
                render={(props) => <Form {...props} />}
                initialValues={values}
                validationSchema={PasswordRecoverySchema}
                onSubmit={this.submitValues}
              />
            </div>
          </div>

        </div>
        {
          loader
          && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )
        }
        {showMessage && NotificationManager.error(alertMessage)}
      </div>
    );
  }
}

export default PasswordConfirm;
