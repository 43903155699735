const skidata = [
  { name: 'Configuration', pathname: 'integrations/skidataConfiguration' },
  { name: 'Devices', pathname: 'integrations/skidataDevices' },
];

const integrationList = {
  skidata,
};

export default integrationList;
