import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import LBPasswordInput from 'components/PasswordInput';

export const Form = (props) => {
  const {
    values: { email, password },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    validateForm,
  } = props;

  const locale = useSelector((state) => state.auth.locale);

  useEffect(() => {
    validateForm();
  }, [locale, validateForm]);

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <TextField
          id="email"
          name="email"
          helperText={touched.email ? errors.email : ''}
          error={touched.email && Boolean(errors.email)}
          label={<IntlMessages id="appModule.email" />}
          fullWidth
          value={email}
          onChange={change.bind(null, 'email')}
          margin="normal"
          className="mt-1 my-sm-3"
        />
        <LBPasswordInput
          id="password"
          name="password"
          helperText={touched.password ? errors.password : ''}
          error={touched.password && Boolean(errors.password)}
          label={<IntlMessages id="appModule.password" />}
          fullWidth
          onChange={change.bind(null, 'password')}
          value={password}
          margin="normal"
          className="mt-1 my-sm-3"
        />

        <div className="mb-3 d-flex align-items-center justify-content-between">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid}>
            <IntlMessages id="appModule.signIn" />
          </Button>

          <Link to="/forgot_password">
            <IntlMessages id="signIn.forgotPassword" />
          </Link>
        </div>
      </fieldset>
    </form>
  );
};
