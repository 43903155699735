import React from 'react';
import { NavLink } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import Badge from '@material-ui/core/Badge';
import { styled } from '@material-ui/core/styles';
import s from '../SidenavContent.module.css';

export default function ListMenuItem({
  isVisible = true, iconClass, textId, pathname, isExact = false, isActive = false, badge, liStyles = null, useActiveClassName = true,
}) {

  const SidebarBadge = styled(Badge)({
    top: '16px',
    right: '40px',
    position: 'absolute',
  });

  function renderBadge(data) {
    if (data) {
      return (
        <SidebarBadge
          badgeContent={data.badge}
          color="primary"
          title={data.message}
          // eslint-disable-next-line react/no-children-prop
          children={[]}
        />
      );
    }

    return null;
  }

  if (!isVisible) return null;
  return (
    <li className={liStyles}>
      <NavLink activeClassName={useActiveClassName ? s.activeLink : ''} className={`${isActive ? s.activeLink : ''}`} to={pathname} exact={isExact}>
        <i className={`zmdi ${iconClass}`} />
        <span className="nav-text">
          <IntlMessages id={textId} />
          {(badge && badge.isVisible)
            ? renderBadge(badge.count)
            : null}
        </span>
      </NavLink>
    </li>
  );
}
