import saMessages from '../locales/es_ES.json';
import esCustomMessages from '../locales/en_ES_custom.json';

const saLang = {
  messages: {
    ...saMessages,
    ...esCustomMessages,
  },
  locale: 'es',
};
export default saLang;
