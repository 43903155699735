import React from 'react';
import { Link } from 'react-router-dom';
import { companiesNames } from 'constants/companies';

import lbLogo from 'assets/images/LB-Logo-big.png';
import communikeyLogo from 'assets/images/communikey2-rgb.svg';
import lbLogoSm from 'assets/images/LobiBox-Logo-small.png';

export default function CompanyLogo({ small = false, big = false }) {

  if (big) {
    if (process.env.REACT_APP_COMPANY_NAME === companiesNames.LOBIBOX) {
      return (
        <Link className="logo-lg" to="/" title="LobiBox">
          <img src={lbLogo} alt="LobiBox" title="LobiBox" />
        </Link>
      );
    }

    if (process.env.REACT_APP_COMPANY_NAME === companiesNames.COMMUNIKEY) {
      return (
        <Link className="logo-lg" to="/" title="Communikey">
          <img src={communikeyLogo} alt="Communikey" title="Communikey" />
        </Link>
      );
    }

    return <img src={lbLogo} alt="LobiBox" title="LobiBox" />;
  }

  if (small) {
    if (process.env.REACT_APP_COMPANY_NAME === companiesNames.LOBIBOX) {
      return (
        <Link className="app-logo mr-2 d-none d-sm-block" to="/app">
          {process.env.REACT_APP_COMPANY_NAME === companiesNames.LOBIBOX && <img src={lbLogoSm} alt="LobiBox" title="LobiBox" />}
        </Link>
      );
    }

    if (process.env.REACT_APP_COMPANY_NAME === companiesNames.COMMUNIKEY) {
      return (
        <Link className="app-logo mr-2 d-none d-sm-block" to="/app">
          {process.env.REACT_APP_COMPANY_NAME === companiesNames.COMMUNIKEY && <img src={communikeyLogo} alt="Communikey" title="Communikey" />}
        </Link>
      );
    }

    return <img src={lbLogoSm} alt="LobiBox" title="LobiBox" />;
  }
}
