import * as Yup from 'yup';
import { ADMIN_PASSWORD } from 'constants/regExp';
import getIntl from '../../lngProvider/intl';

const getErrorMsg = (id) => getIntl().formatMessage({ id });

export const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email(() => getErrorMsg('validation.invalidAddress'))
    .required(() => getErrorMsg('validation.fieldIsRequired')),
  password: Yup.string()
    .min(8, () => getErrorMsg('validationChangePass.passMinLength'))
    .max(128, () => getErrorMsg('validationChangePass.passMaxLength'))
    .required(() => getErrorMsg('validation.fieldIsRequired')),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email(() => getErrorMsg('validation.invalidAddress'))
    .required(() => getErrorMsg('validation.fieldIsRequired')),
});

export const PasswordRecoverySchema = Yup.object().shape({
  password: Yup.string()
    .min(8, () => getErrorMsg('validationChangePass.passMinLength'))
    .max(128, () => getErrorMsg('validationChangePass.passMaxLength'))
    .matches(ADMIN_PASSWORD, () => getErrorMsg('validation.passValidation'))
    .required(() => getErrorMsg('validation.fieldIsRequired')),
  passwordConfirm: Yup.string()
    .min(8, () => getErrorMsg('validationChangePass.passMinLength'))
    .max(128, () => getErrorMsg('validationChangePass.passMaxLength'))
    .matches(ADMIN_PASSWORD, () => getErrorMsg('validation.passValidation'))
    .required(() => getErrorMsg('validation.fieldIsRequired'))
    .oneOf([Yup.ref('password')], () => getErrorMsg('validationChangePass.passNotMatch')),
});
