import generateCompanyObject from 'util/switchCompanyObject';
import {
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANY_SUCCESS,
  FETCH_ALL_COMPANIES_SUCCESS,
  SHOW_COMPANY_MESSAGE,
  HIDE_COMPANY_MESSAGE,
  ON_SHOW_COMPANY_LOADER,
  ON_HIDE_COMPANY_LOADER,
  SWITCH_COMPANY,
  SIGNOUT_USER,
  UPDATE_CURRENT_COMPANY,
} from '../ActionTypes';

const INIT_STATE = {
  companies: [],
  allCompanies: [],
  currentCompany: {
    id: null,
    name: '',
    turnOnDirectoryCode: false,
    pstnRollOverAfterMobileApp: false,
    role: '',
  },
  loader: false,
  alertMessage: '',
  showMessage: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_SUCCESS: {
      return {
        ...state,
        loader: false,
        companies: action.payload.companies,
      };
    }
    case FETCH_COMPANY_SUCCESS: {
      return {
        ...state,
        currentCompany: generateCompanyObject(action.payload.company),
      };
    }
    case FETCH_ALL_COMPANIES_SUCCESS: {
      let activeCompany = {};
      let isNeedToCreateNewCompany = true;

      if (action.payload.companyIdFromUrl) {
        [activeCompany] = action.payload.allCompanies.filter((company) => company.id === +action.payload.companyIdFromUrl);
        isNeedToCreateNewCompany = false;
      } else if (action.payload.currentCompanyID) {
        const [filteredCompany] = action.payload.allCompanies.filter((company) => company.id === +action.payload.currentCompanyID);
        if (filteredCompany) {
          activeCompany = filteredCompany;
          isNeedToCreateNewCompany = false;
        }
      }
      return {
        ...state,
        loader: false,
        allCompanies: action.payload.allCompanies,
        currentCompany: generateCompanyObject(isNeedToCreateNewCompany ? action.payload.allCompanies[0] : activeCompany),
      };
    }
    case SHOW_COMPANY_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_COMPANY_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
      };
    }
    case ON_SHOW_COMPANY_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_COMPANY_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    case SWITCH_COMPANY: {
      return {
        ...state,
        currentCompany: action.payload,
      };
    }
    case UPDATE_CURRENT_COMPANY: {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
        },
        allCompanies: state.allCompanies.map((item) => {
          if (item.id === state.currentCompany.id) {
            return action.payload;
          }
          return item;
        }),
      };
    }
    case SIGNOUT_USER: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};
