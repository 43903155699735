import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  GOT_RESET_PASSWORD_MESSAGE,
  RESET_PASSWORD_SUCCESS,
  FETCH_USER_INFO_SUCCESS,
  SIGNOUT_USER,
  SWITCH_LANGUAGE,
  CHANGE_DIRECTION,
} from '../ActionTypes';

import { LOCALE } from '../../lngProvider/language';

const rltLocale = ['ar'];

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  infoMessage: '',
  initURL: '',
  authUser: localStorage.getItem('token'),
  email: '',
  firstName: '',
  lastName: '',
  isSuperuser: false,
  locale: 'en',
  isDirectionRTL: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale),
      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL,
      };
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        infoMessage: action.payload,
        loader: false,
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case GOT_RESET_PASSWORD_MESSAGE: {
      return {
        ...state,
        infoMessage: action.payload,
        loader: false,
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    case FETCH_USER_INFO_SUCCESS: {
      return {
        ...state,
        email: action.payload.email,
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        registrationDate: action.payload.date_joined,
        lastLogin: action.payload.last_login,
        isSuperuser: action.payload.is_superuser,
        locale: LOCALE[action.payload.language],
      };
    }
    case SIGNOUT_USER: {
      return {
        ...state,
        email: '',
        firstName: '',
        lastName: '',
        authUser: null,
        initURL: '/app',
        loader: false,
        isSuperuser: false,
      };
    }
    default:
      return state;
  }
};
