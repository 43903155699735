import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { changeLanguage } from 'services/apiRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { catchError } from 'services/catchError';
import { switchLanguage } from '../../store/actions/Auth';
import s from './LangSwitch.module.css';
import { LANGUAGE } from '../../lngProvider/language';

export default function LangSwitch({ shouldSyncWithBackend = true }) {

  const currentAppLocale = useSelector((state) => state.auth.locale);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const locale = e.target.value;
    dispatch(switchLanguage(locale));
    const language = LANGUAGE[locale];

    if (shouldSyncWithBackend) {
      changeLanguage(language).catch((error) => catchError(error));
    }

  };

  const renderLanguageList = (language) => {
    const langKeys = Object.keys(language).sort();
    return langKeys.map((lang) => (<MenuItem key={lang} value={lang}>{lang.toUpperCase()}</MenuItem>));
  };

  return (
    <div className={s.container}>
      <Select
        id="lang-switch"
        value={currentAppLocale}
        style={{ width: 80 }}
        className={s.selectLanguage}
        onChange={handleChange}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        SelectDisplayProps={{ style: { padding: 8 } }}
      >
        {renderLanguageList(LANGUAGE)}
      </Select>
    </div>
  );
}
