import { NotificationManager } from 'react-notifications';
import { fetchCompanies } from 'services/apiRoutes';

import { composeErrorMessage } from 'util/helpers';

const handleUnauthorized = (error, props) => {
  props.history.push({
    pathname: '/signin',
    state: { alertMessage: error.detail || 'Unauthorized' },
  });
};

const hasProp = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

export const catchError = (error, props) => {
  console.error(error.response, 'catch error');
  if (((hasProp(error, 'status')
    && error.status === 401)
    || (hasProp(error.response, 'status')
      && error.response.status === 401)) && props) {
    handleUnauthorized(error.response.data, props);
    return;
  }

  if (hasProp(error.response, 'status')
    && error.response.status === 403 && props) {
    const errorMessage = error.response.data && error.response.data.detail;
    fetchCompanies({ all: true }).then().catch((error) => {
      props.history.push({
        pathname: '/signin',
      });
    });
    props.history.push({
      pathname: '/app/notfoundpage',
      state: {
        errorCode: 403,
        errorMessage,
      },
    });
    return;
  }

  if (!error.response) {
    NotificationManager.error('Unknown Server Error');
    return;
  }
  if (((hasProp(error, 'status')
    && error.status === 404)
    || (hasProp(error.response, 'status')
      && error.response.status === 404)) && props) {
    props.history.push('/app/notfoundpage');
    return;
  }

  if (((hasProp(error, 'status')
    && error.status === 500)
    || (hasProp(error.response, 'status')
      && error.response.status === 500)) && props) {
    const errorMessage = error.response.data && error.response.data.detail;
    props.history.push({
      pathname: '/app/notfoundpage',
      state: {
        errorCode: 500,
        errorMessage,
      },
    });
    return;
  }

  if (hasProp(error.response.data, 'detail')
    && error.response.data.detail) {
    NotificationManager.error(error.response.data.detail, '', 5000);
  } else {
    const messages = composeErrorMessage(error.response.data);
    messages.map((msg) => NotificationManager.error(msg, '', 5000));

  }
};
