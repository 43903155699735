import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isIOS, isMobile } from 'react-device-detect';

import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'store/ActionTypes';
import TopNav from 'components/TopNav';
import PrivateRoute from '../util/PrivateRoute';
import asyncComponent from '../util/asyncComponent';
import { ADMIN_ROLES } from '../util/adminRoles';

class App extends React.Component {

  render() {
    const {
      match, drawerType, navigationStyle, horizontalNavPosition,
    } = this.props;
    // eslint-disable-next-line no-nested-ternary
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    // set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER)
              && <TopNav styleName="app-top-header" />}
            <Header />
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER)
              && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route
                  exact
                  path={`${match.url}`}
                  component={asyncComponent(() => import('./routes/home'))} />

                <Route
                  exact
                  path={`${match.url}/profile`}
                  component={asyncComponent(() => import('./routes/profile'))} />

                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/admins`}
                  component={asyncComponent(() => import('./routes/companyAdmins'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/admins/add`}
                  component={asyncComponent(() => import('./routes/companyAdmins/addAdmin'))}
                  privateFor={[ADMIN_ROLES.SECURITY, ADMIN_ROLES.ADMIN_WITH_SECURITY_ACCESS, ADMIN_ROLES.ADMIN]} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/admins/:adminId`}
                  component={asyncComponent(() => import('./routes/companyAdmins/changeAdmin'))}
                  privateFor={[ADMIN_ROLES.SECURITY, ADMIN_ROLES.ADMIN_WITH_SECURITY_ACCESS, ADMIN_ROLES.ADMIN]} />

                <Route
                  exact
                  path={`${match.url}/companies/:companyId/buildings`}
                  component={asyncComponent(() => import('./routes/buildings'))} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/buildings/add`}
                  component={asyncComponent(() => import('./routes/buildings/addBuilding'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/buildings/:buildingId`}
                  component={asyncComponent(() => import('./routes/buildings/changeBuilding'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />

                <Route
                  exact
                  path={`${match.url}/companies/:companyId/vunits`}
                  component={asyncComponent(() => import('./routes/vunits'))} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/vunits/add`}
                  component={asyncComponent(() => import('./routes/vunits/addVu'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/addVunits/:snVU`}
                  component={asyncComponent(() => import('./routes/selectCompany'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/vunits/:vuId`}
                  component={asyncComponent(() => import('./routes/vunits/changeVu'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />

                <Route
                  exact
                  path={`${match.url}/companies/:companyId/doorControllers`}
                  component={asyncComponent(() => import('./routes/doorControllers'))} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/doorControllers/add`}
                  component={asyncComponent(() => import('./routes/doorControllers/addDoorController'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/doorControllers/:doorControllerId`}
                  component={asyncComponent(() => import('./routes/doorControllers/changeDoorController'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />

                <Route
                  exact
                  path={`${match.url}/companies/:companyId/members`}
                  component={asyncComponent(() => import('./routes/tenants'))} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/members/add`}
                  component={asyncComponent(() => import('./routes/tenants/addTenant'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />
                <Route
                  exact
                  path={`${match.url}/companies/:companyId/members/:tenantId`}
                  component={asyncComponent(() => import('./routes/tenants/changeTenant'))} />

                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/schedules`}
                  component={asyncComponent(() => import('./routes/schedules'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/schedules/add`}
                  component={asyncComponent(() => import('./routes/schedules/addSchedule'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/schedules/:scheduleId`}
                  component={asyncComponent(() => import('./routes/schedules/changeSchedule'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />

                <Route
                  exact
                  path={`${match.url}/companies/:companyId/accessLevels`}
                  component={asyncComponent(() => import('./routes/accessLevels'))}
                  />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/accessLevels/add`}
                  component={asyncComponent(() => import('./routes/accessLevels/addAccessLevel'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/accessLevels/:accessLevelId`}
                  component={asyncComponent(() => import('./routes/accessLevels/changeAccessLevel'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />

                <Route
                  exact
                  path={`${match.url}/companies`}
                  component={asyncComponent(() => import('./routes/companies'))} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId`}
                  component={asyncComponent(() => import('./routes/companies/changeCompany'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />

                <Route
                  exact
                  path={`${match.url}/companies/:companyId/vcodes`}
                  component={asyncComponent(() => import('./routes/visitorCodes'))} />
                <PrivateRoute
                  exact
                  path={`${match.url}/companies/:companyId/vcodes/add`}
                  component={asyncComponent(() => import('./routes/visitorCodes/addVisitorCode'))}
                  privateFor={[ADMIN_ROLES.SECURITY]} />
                <Route
                  exact
                  path={`${match.url}/companies/:companyId/vcodes/:vcId`}
                  component={asyncComponent(() => import('./routes/visitorCodes/changeVisitorCode'))} />

                <Route
                  exact
                  path={`${match.url}/companies/:companyId/events`}
                  component={asyncComponent(() => import('./routes/eventsTransactions'))} />
                <Route
                  exact
                  path={`${match.url}/companies/:companyId/events/:eventId`}
                  component={asyncComponent(() => import('./routes/eventsTransactions/eventDetails'))} />

                <Route
                  exact
                  path={`${match.url}/companies/:companyId/visitorImages`}
                  component={asyncComponent(() => import('./routes/visitorImages'))} />
                <Route
                  exact
                  path={`${match.url}/companies/:companyId/doorsReport`}
                  component={asyncComponent(() => import('./routes/reports/doors'))} />
                <Route
                  exact
                  path={`${match.url}/companies/:companyId/eventsReport`}
                  component={asyncComponent(() => import('./routes/reports/events'))} />
                <Route
                  exact
                  path={`${match.url}/companies/:companyId/membersReport`}
                  component={asyncComponent(() => import('./routes/reports/members'))} />
                <Route
                  exact
                  path={`${match.url}/companies/:companyId/visitorCodesReport`}
                  component={asyncComponent(() => import('./routes/reports/visitorCodes'))} />
                <Route
                  exact
                  path={`${match.url}/companies/:companyId/errorsReport`}
                  component={asyncComponent(() => import('./routes/reports/errors'))} />

                <Route
                  exact
                  path={`${match.url}/companies/:companyId/integrations/skidataConfiguration`}
                  component={asyncComponent(() => import('./routes/integrations/skidata/configuration'))} />
                <Route
                  exact
                  path={`${match.url}/companies/:companyId/integrations/skidataDevices`}
                  component={asyncComponent(() => import('./routes/integrations/skidata/devices'))} />
                <Route
                  exact
                  path={`${match.url}/companies/:companyId/integrations/skidataDevices/:deviceId`}
                  component={asyncComponent(() => import('./routes/integrations/skidata/devices/editDevice'))} />

                <Route component={asyncComponent(() => import('components/Error404'))} />
              </Switch>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
