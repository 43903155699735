import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER,
} from 'store/ActionTypes';
import { toggleCollapsedNav } from 'store/actions/Setting';
import Menu from 'components/TopNav/Menu';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';
import s from './Header.module.css';
import LangSwitch from '../LangSwitch/LangSwitch';
import ExpireToken from '../ExpireToken/ExpireToken';
import CompanyLogo from '../CompanyLogo/CompanyLogo';

class Header extends React.Component {

  constructor() {
    super();
    this.state = { userInfo: false };
  }

  onUserInfoSelect = () => {
    this.setState((prevState) => ({
      userInfo: !prevState.userInfo,
    }));
  };

  onToggleCollapsedNav = () => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  render() {
    const { drawerType, navigationStyle, horizontalNavPosition } = this.props;

    let drawerStyle = 'd-none';

    if (drawerType.includes(FIXED_DRAWER)) {
      drawerStyle = 'd-block d-xl-none';
    } else if (drawerType.includes(COLLAPSED_DRAWER)) {
      drawerStyle = 'd-block';
    }

    return (
      <AppBar
        className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
        <Toolbar className="app-toolbar" disableGutters={false}>
          {navigationStyle === HORIZONTAL_NAVIGATION
            ? (
              <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
                <span className="jr-menu-icon">
                  <span className="menu-icon" />
                </span>
              </div>
            )
            : (
              <IconButton
                className={`jr-menu-icon mr-3 ${drawerStyle}`}
                aria-label="Menu"
                onClick={this.onToggleCollapsedNav}>
                <span className="menu-icon" />
              </IconButton>
            )}

          <CompanyLogo small />
          <div className={s.langSwitch}>
            <LangSwitch />
          </div>
          {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER)
            && <Menu />}

          <ul className="header-notifications list-inline ml-auto">
            {navigationStyle === HORIZONTAL_NAVIGATION
              && (
                <li className="list-inline-item user-nav">
                  <Dropdown
                    className="quick-menu"
                    isOpen={this.state.userInfo}
                    toggle={this.onUserInfoSelect.bind(this)}>

                    <DropdownToggle
                      className="d-inline-block"
                      tag="span"
                      data-toggle="dropdown">
                      <IconButton className="icon-btn size-30">
                        <Avatar
                          alt="..."
                          src="https://via.placeholder.com/150x150"
                          className="size-30"
                        />
                      </IconButton>
                    </DropdownToggle>

                    <DropdownMenu right>
                      <UserInfoPopup />
                    </DropdownMenu>
                  </Dropdown>
                </li>
              )}
          </ul>

          <div className="ellipse-shape" />
          <ExpireToken />

        </Toolbar>
      </AppBar>
    );
  }

}

const mapStateToProps = ({ settings }) => {
  const {
    drawerType, navigationStyle, horizontalNavPosition,
  } = settings;
  return {
    drawerType, navigationStyle, horizontalNavPosition,
  };
};

export default withRouter(connect(mapStateToProps, { toggleCollapsedNav })(Header));
