import enMessages from '../locales/en_US.json';
import enCustomMessages from '../locales/en_US_custom.json';

const enLang = {
  messages: {
    ...enMessages,
    ...enCustomMessages,
  },
  locale: 'en',
};
export default enLang;
