import axios from 'axios';

import { API_BASE_URL } from '../constants';
import { store } from '../MainApp';

const instance = axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.request.use((config) => {
  const token = store.getState().auth.authUser;
  const { locale } = store.getState().auth;

  // eslint-disable-next-line no-param-reassign
  config.headers['Accept-Language'] = locale;

  if (token && !config.headers.Authorization && (config.url !== 'user/password/set/')) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Token ${token}`;

  }
  return config;

}, (error) => Promise.reject(error));

export default instance;
