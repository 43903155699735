export const LANGUAGE = {
  en: 'English',
  fr: 'Français',
  es: 'Española',
};

export const LOCALE = {
  English: 'en',
  Français: 'fr',
  Española: 'es',
};
