/* eslint-disable no-underscore-dangle */
import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  FETCH_COMPANIES,
  FETCH_ALL_COMPANIES,
  FETCH_COMPANY,
} from '../ActionTypes';
import {
  showCompanyLoader,
  hideCompanyLoader,
  showCompanyMessage,
} from '../actions/Companies';
import {
  fetchCompaniesSuccess,
  fetchAllCompaniesSuccess,
  fetchCompanySuccess,
} from '../actions';
import API from '../../services/api';

const _getCompaniesRequest = async (page = 1) => API.get('companies/', { // calls two times
  params: {
    page,
  },
})
  .then((companies) => companies.data)
  .catch((error) => error.response);

const _getAllCompaniesRequest = async (all = true) => API.get('companies/', {
  params: {
    all,
  },
})
  .then((allCompanies) => allCompanies.data)
  .catch((error) => error.response);

const _fetchCompany = async (companyId) => API.get(`companies/${companyId}/`)
  .then((companyResp) => companyResp.data)
  .catch((error) => error.response);

function* _getCompanies({ payload }) {
  const { page } = payload.page;
  const { companyIdFromUrl, location } = payload;
  try {
    yield put(showCompanyLoader());
    const companies = yield call(_getCompaniesRequest, page);
    yield put(hideCompanyLoader());

    if (companies.status === 401 || companies.status === 403) {
      yield put(push('/signin/', location));
    }

    if (Object.hasOwn(companies, 'call_transaction_list') && Array.isArray(companies.call_transaction_list)
      && companies.call_transaction_list.length > 0) {
      yield put(fetchCompaniesSuccess(companies.call_transaction_list, companyIdFromUrl));
    } else if (Object.hasOwn(companies, 'detail')) {
      yield put(showCompanyMessage(companies.detail));
    } else {
      // todo something if no companies
    }
  } catch (error) {
    yield put(showCompanyMessage(error));
  }
}

function* _getAllCompanies({ payload }) {
  const { companyIdFromUrl, currentCompanyID, location } = payload;
  try {
    yield put(showCompanyLoader());
    const allCompanies = yield call(_getAllCompaniesRequest);
    yield put(hideCompanyLoader());

    if (allCompanies.status === 401) {
      yield put(push('/signin/', location));
    }

    if (Array.isArray(allCompanies) && allCompanies.length > 0) {
      yield put(fetchAllCompaniesSuccess(allCompanies, companyIdFromUrl, currentCompanyID));
    } else if (Object.hasOwn(allCompanies, 'detail')) {
      yield put(showCompanyMessage(allCompanies.detail));
    } else {
      // todo something if no companies
    }

  } catch (error) {
    yield put(showCompanyMessage(error));
    yield put(push('/app/notfound/'));
  }
}

function* _getCompany({ payload }) {
  const { companyId } = payload;
  try {
    const company = yield call(_fetchCompany, companyId);
    yield put(fetchCompanySuccess(company));
  } catch (error) {
    console.log(error);
  }
}

export function* getCompanies() {
  yield takeLatest(FETCH_COMPANIES, _getCompanies);
}

export function* getAllCompanies() {
  yield takeLatest(FETCH_ALL_COMPANIES, _getAllCompanies);
}

export function* getCompany() {
  yield takeLatest(FETCH_COMPANY, _getCompany);
}

export default function* rootSaga() {
  yield all([
    fork(getCompanies),
    fork(getAllCompanies),
    fork(getCompany),
  ]);
}
