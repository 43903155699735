import {
  all, call, fork, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { catchError } from 'services/catchError';
import { composeErrorMessage } from 'util/helpers';
import {
  SIGNIN_USER,
  RESET_PASSWORD,
  PASSWORD_CONFIRMATION,
  PASSWORD_SETUP,
  FETCH_USER_INFO,
  UPDATE_USER_INFO,
} from '../ActionTypes';
import {
  showAuthMessage,
  userSignInSuccess,
  userGotResetPasswordMessage,
  userResetPasswordSuccess,
  fetchUserInfoSuccess,
} from '../actions/Auth';
import API from '../../services/api';

const signInUserWithEmailPasswordRequest = async (email, password) => API.post('user/token/', {
  email,
  password,
})
  .then((authUser) => authUser.data)
  .catch((error) => error.response);

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (!Object.hasOwn(signInUser, 'token')) {
      if (Object.hasOwn(signInUser, 'data')) {
        yield put(showAuthMessage(composeErrorMessage(signInUser.data)));
      } else {
        yield put(showAuthMessage('fail login'));
      }
    } else {
      localStorage.setItem('token', signInUser.token);
      yield put(userSignInSuccess(signInUser.token));
    }
  } catch (error) {
    yield put(showAuthMessage('fail login'));
  }
}

const resetPasswordRequest = async (email) => API.post('user/password/recovery/', { email })
  .then((resp) => resp.data)
  .catch((error) => error.response.data);

function* resetPassword({ payload }) {
  const { email } = payload;

  try {
    const resetEmailResp = yield call(resetPasswordRequest, email);
    if (Object.hasOwn(resetEmailResp, 'detail')) {
      yield put(userGotResetPasswordMessage(resetEmailResp.detail));
      yield put(push('/forgot_password_done'));
    } else if (Object.hasOwn(resetEmailResp, 'email') && Array.isArray(resetEmailResp.email)) {
      yield put(showAuthMessage(resetEmailResp.email[0]));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

const confirmPasswordRequest = async (password, passwordConfirm, key) => API.post('user/password/recovery/confirm/',
  {
    key,
    new_password1: password,
    new_password2: passwordConfirm,
  })
  .then((resp) => resp.data)
  .catch((error) => error.response.data);

const setupPasswordRequest = async (password, passwordConfirm, uid, token) => API.post('user/password/set/',
  {
    uid,
    token,
    new_password1: password,
    new_password2: passwordConfirm,
  })
  .then((resp) => resp.data)
  .catch((error) => error.response.data);

const fetchUserRequest = async () => API.get('user/me/')
  .then((resp) => resp.data)
  .catch(catchError);

const updateUserRequest = async (email, firstName, lastName) => API.patch('user/me/', {
  email,
  first_name: firstName,
  last_name: lastName,
})
  .then((resp) => resp.data)
  .catch(catchError);

function* confirmPassword({ payload }) {
  const { password, passwordConfirm, key } = payload;

  try {
    const resetPasswordResp = yield call(confirmPasswordRequest, password, passwordConfirm, key);

    if (resetPasswordResp.detail) {
      yield put(userResetPasswordSuccess(resetPasswordResp.detail));
      yield put(push('/reset/done/'));
    } else if (Array.isArray(resetPasswordResp.new_password2)) {
      yield put(showAuthMessage(resetPasswordResp.new_password2[0]));
    } else if (Array.isArray(resetPasswordResp.non_field_errors)) {
      yield put(push('/signin/'));
      yield put(showAuthMessage(resetPasswordResp.non_field_errors[0]));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* setupPassword({ payload }) {
  const {
    password, passwordConfirm, uid, token,
  } = payload;

  try {
    const resetPasswordResp = yield call(setupPasswordRequest, password, passwordConfirm, uid, token);

    if (resetPasswordResp.detail) {
      yield put(userResetPasswordSuccess(resetPasswordResp.detail));
      yield put(push('/setup/done/'));
    } else if (Array.isArray(resetPasswordResp.new_password2)) {
      yield put(showAuthMessage(resetPasswordResp.new_password2[0]));
    } else if (Array.isArray(resetPasswordResp.non_field_errors)) {
      yield put(push('/signin/'));
      yield put(showAuthMessage(resetPasswordResp.non_field_errors[0]));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* fetchUser() {
  try {
    const user = yield call(fetchUserRequest);
    if (user && Object.prototype.hasOwnProperty.call(user, 'email', 'first_name', 'last_name')) {
      yield put(fetchUserInfoSuccess(user));
    }
  } catch (error) {
    console.error(error);
  }
}

function* updateUser({ payload }) {
  const { email, firstName, lastName } = payload;
  try {
    const user = yield call(updateUserRequest, email, firstName, lastName);
    if (Object.prototype.hasOwnProperty.call(user, 'email', 'first_name', 'last_name')) {
      yield put(fetchUserInfoSuccess(user));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* resetUserPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* confirmUserPassword() {
  yield takeEvery(PASSWORD_CONFIRMATION, confirmPassword);
}

export function* setupUserPassword() {
  yield takeEvery(PASSWORD_SETUP, setupPassword);
}

export function* fetchUserInfo() {
  yield takeLatest(FETCH_USER_INFO, fetchUser);
}

export function* updateUserInfo() {
  yield takeEvery(UPDATE_USER_INFO, updateUser);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(resetUserPassword),
    fork(confirmUserPassword),
    fork(setupUserPassword),
    fork(fetchUserInfo),
    fork(updateUserInfo),
  ]);
}
