import React from 'react';
import { connect } from 'react-redux';

import { hideMessage, showAuthLoader, passwordConfirmation } from 'store/actions/Auth';
import PasswordConfirm from './PasswordConfirm';

class PasswordRecovery extends React.Component {
  submitValues = (password, passwordConfirm) => {
    const { token } = this.props.match.params;

    this.props.showAuthLoader();
    this.props.passwordConfirmation({
      password,
      passwordConfirm,
      key: token,
    });
  };

  render() {
    const {
      showMessage,
      loader,
      alertMessage,
      hideMessage,
    } = this.props;

    return (
      <PasswordConfirm
        passwordType="reset"
        showMessage={showMessage}
        loader={loader}
        alertMessage={alertMessage}
        hideMessage={hideMessage}
        submitValues={this.submitValues}
      />
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const {
    loader, alertMessage, showMessage, authUser, infoMessage,
  } = auth;
  return {
    loader, alertMessage, showMessage, authUser, infoMessage,
  };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  passwordConfirmation,
})(PasswordRecovery);
