import {
  FETCH_ALL_INTEGRATIONS,
  FETCH_ALL_INTEGRATIONS_SUCCESS,
  UPDATE_INTEGRATIONS_LIST,
  UPDATE_INTEGRATION_CONFIGURATIONS,
  RESET_INTEGRATIONS_DATA,
} from '../ActionTypes';

export const fetchAllIntegrations = (companyId) => ({
  type: FETCH_ALL_INTEGRATIONS,
  payload: companyId,
});

export const fetchAllIntegrationsSuccess = (integrationList) => ({
  type: FETCH_ALL_INTEGRATIONS_SUCCESS,
  payload: integrationList,
});
export const updateIntegrationsList = (integrationList) => ({
  type: UPDATE_INTEGRATIONS_LIST,
  payload: integrationList,
});

export const updateIntegrationConfiguration = (integrationSettings) => ({
  type: UPDATE_INTEGRATION_CONFIGURATIONS,
  payload: integrationSettings,
});

export const resetIntegrationsData = () => ({
  type: RESET_INTEGRATIONS_DATA,
});
