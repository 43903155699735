import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNUP_USER_SUCCESS,
  RESET_PASSWORD,
  GOT_RESET_PASSWORD_MESSAGE,
  PASSWORD_CONFIRMATION,
  PASSWORD_SETUP,
  RESET_PASSWORD_SUCCESS,
  FETCH_USER_INFO,
  FETCH_USER_INFO_SUCCESS,
  UPDATE_USER_INFO,
  SWITCH_LANGUAGE,
} from '../ActionTypes';

export const userSignIn = (user) => ({
  type: SIGNIN_USER,
  payload: user,
});

export const passwordConfirmation = (data) => ({
  type: PASSWORD_CONFIRMATION,
  payload: data,
});

export const setupPassword = (data) => ({
  type: PASSWORD_SETUP,
  payload: data,
});

export const userResetPassword = (email) => ({
  type: RESET_PASSWORD,
  payload: email,
});

export const userSignOut = () => ({
  type: SIGNOUT_USER,
});
export const userSignUpSuccess = (authUser) => ({
  type: SIGNUP_USER_SUCCESS,
  payload: authUser,
});

export const userSignInSuccess = (authUser) => ({
  type: SIGNIN_USER_SUCCESS,
  payload: authUser,
});

export const userResetPasswordSuccess = (message) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: message,
});

export const userGotResetPasswordMessage = (message) => ({
  type: GOT_RESET_PASSWORD_MESSAGE,
  payload: message,
});

export const showAuthMessage = (message) => ({
  type: SHOW_MESSAGE,
  payload: message,
});

export const setInitUrl = (url) => ({
  type: INIT_URL,
  payload: url,
});

export const showAuthLoader = () => ({
  type: ON_SHOW_LOADER,
});

export const hideMessage = () => ({
  type: HIDE_MESSAGE,
});

export const hideAuthLoader = () => ({
  type: ON_HIDE_LOADER,
});

export const fetchUserInfo = () => ({
  type: FETCH_USER_INFO,
});

export const fetchUserInfoSuccess = (user) => ({
  type: FETCH_USER_INFO_SUCCESS,
  payload: user,
});

export const updateUserInfo = (personalInfo) => ({
  type: UPDATE_USER_INFO,
  payload: personalInfo,
});

export const switchLanguage = (locale) => ({
  type: SWITCH_LANGUAGE,
  payload: locale,
});
