import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { NotificationManager } from 'react-notifications';
import { delay } from 'lodash';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  hideMessage, showAuthLoader, userSignIn, userSignOut,
} from 'store/actions/Auth';
import { getCompanyName } from 'util/getCompanyName';
import s from './SignIn.module.css';
import LangSwitch from '../../components/LangSwitch/LangSwitch';
import { SigninSchema } from '../validationSchemas';
import { Form } from './form';
import CompanyLogo from '../../components/CompanyLogo/CompanyLogo';

const companyName = getCompanyName();

class SignIn extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = { email: '', password: '' };
    localStorage.removeItem('token');
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.alertMessage) {
      NotificationManager.error(this.props.location.state.alertMessage, '', 5000);
    }
    this.props.userSignOut();
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      delay(() => { this.props.hideMessage(); }, 100);
    }
    if (this.props.authUser !== null) {
      let goalUrl = '/app';
      if (this.props.location?.state?.pathname) {
        goalUrl = this.props.location?.state?.pathname;
      }
      this.props.history.push(goalUrl);
    }
  }

  submitValues = ({ email, password }) => {
    this.props.showAuthLoader();
    this.props.userSignIn({ email, password });
  };

  render() {
    const { email, password } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    const values = { email, password };

    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <CompanyLogo big />
          </div>

          <div className="app-login-content">
            <div className={s.langSwitch}>
              <LangSwitch shouldSyncWithBackend={false} />
            </div>
            <div className="app-login-header mb-4">
              <h1><IntlMessages id="appModule.loginHeader" values={{ companyName }} /></h1>
            </div>

            <div className="app-login-form">
              <Formik
                render={(props) => <Form {...props} />}
                initialValues={values}
                validationSchema={SigninSchema}
                onSubmit={this.submitValues}
              />
            </div>
          </div>

        </div>
        {
          loader
          && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )
        }
        {showMessage && NotificationManager.error(alertMessage)}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const {
    loader, alertMessage, showMessage, authUser,
  } = auth;
  return {
    loader, alertMessage, showMessage, authUser,
  };
};

export default connect(mapStateToProps, {
  userSignIn,
  userSignOut,
  hideMessage,
  showAuthLoader,
})(SignIn);
