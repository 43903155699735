import React, { Component } from 'react';
import { connect } from 'react-redux';
import Nprogress from 'nprogress';
import ReactPlaceholder from 'react-placeholder';

import 'nprogress/nprogress.css';
import 'react-placeholder/lib/reactPlaceholder.css';

import { fetchCompanies, fetchAllCompanies, fetchCompany } from 'store/actions/Companies';
import { fetchUserInfo } from 'store/actions/Auth';
import CircularProgress from '../components/CircularProgress/index';

export default function asyncComponent(importComponent) {
  class AsyncFunc extends Component {

    constructor(props) {
      super(props);
      const {
        currentCompany,
        fetchCompanies,
        location,
      } = props;

      if (location.state && location.state.error === '404') this.props.fetchAllCompanies({ currentCompanyID: currentCompany.id, location });

      const companyIdFromUrl = Object.hasOwn(props.match.params, 'companyId')
        && props.match.params.companyId;

      const propsObj = { location };

      if (companyIdFromUrl) {
        propsObj.companyIdFromUrl = companyIdFromUrl;
      }

      const isVunitsSection = props.history.location.pathname.includes('/vunits');
      const isMembersSection = props.history.location.pathname.includes('/members');

      if (isVunitsSection || isMembersSection) {
        fetchCompanies(propsObj);
      }

      if (!currentCompany.id) {
        this.getInitialCompany(propsObj);
      }

      if (currentCompany.id) {
        this.getCurrentCompany(currentCompany.id);
      }

      this.state = { component: null };
    }

    async componentDidMount() {
      Nprogress.configure({ showSpinner: false });
      Nprogress.start();
      this.mounted = true;
      const { default: Component } = await importComponent();
      Nprogress.done();
      if (this.mounted && this.props.currentCompany.id) {
        this.setState({
          component: <Component {...this.props} />,
        });
      }
    }

    async componentDidUpdate(prevProps) {
      if (prevProps !== this.props) {
        const { default: Component } = await importComponent();
        this.receiveProps = true;
        if (this.mounted && this.receiveProps) {
          this.setState({ component: <Component {...this.props} /> });
        }
      }
    }

    componentWillUnmount() {
      this.mounted = false;
      this.receiveProps = false;
    }

    async getInitialCompany(propsObj) {
      await this.props.fetchUserInfo();
      await this.props.fetchAllCompanies(propsObj);
      await this.props.fetchCompanies(propsObj);
    }

    async getCurrentCompany(companyId) {
      await this.props.fetchCompany(companyId);
    }

    render() {
      const Component = this.state.component
        || (
          <div
            className="loader-view"
            style={{ height: 'calc(100vh - 200px)' }}>
            <CircularProgress />
          </div>
        );
      return (
        <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
          {Component}
        </ReactPlaceholder>
      );
    }
  }

  const mapStateToProps = ({ company }) => {
    const {
      currentCompany,
    } = company;
    return {
      currentCompany,
    };
  };

  return connect(mapStateToProps,
    {
      fetchCompanies, fetchAllCompanies, fetchUserInfo, fetchCompany,
    })(AsyncFunc);
}
