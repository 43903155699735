import mtz from 'moment-timezone';

import { UNKNOWN_SERVER_ERROR_MESSAGE } from 'constants/messages';

export const composeErrorMessage = (messageObject) => {
  const result = [];

  if (typeof messageObject !== 'object') {
    return [UNKNOWN_SERVER_ERROR_MESSAGE];
  }
  const objKeysArray = Object.keys(messageObject);

  objKeysArray.forEach((key) => {
    if (Array.isArray(messageObject[key])) {
      messageObject[key].forEach((_, i) => {
        const msg = messageObject[key][i];
        const isNonFieldError = key === 'non_field_errors';
        result.push(isNonFieldError ? msg : `${key} : ${msg}`);

      });
    }
    if (typeof messageObject[key] === 'object' && !Array.isArray(messageObject[key])) {
      const interimResult = composeErrorMessage(messageObject[key]);
      result.push(...interimResult);
    } else if (typeof messageObject[key] === 'string') {
      result.push(messageObject[key]);
    }

  });
  return result;
};

export const composeTimestampWithLocalUTC = (timestamp) => mtz(timestamp).utc(0).format('MMMM D, YYYY h:mm:ss A');

export const composeVuFullName = (vu, buildings) => {
  if (!vu) {
    return '-';
  }
  const building = buildings && buildings.find((building) => building.id === vu.building);
  return `${vu.name} (${building && building.name})`;
};

export const composeTenantFullName = (tenant) => {
  if (!tenant) {
    return '-';
  }
  const firstName = tenant.first_name || '';
  const lastName = tenant.last_name || '';
  return `${firstName} ${lastName}`;
};

export const composeSecurity = (security) => {
  if (!security) {
    return '-';
  }
  return security;
};

export const composeVuForLookup = (availableVu) => {
  const filterData = availableVu.map((vu) => ({ value: `intercom|||${vu.id}`, label: vu.name }));
  return filterData;
};

export const composeDoorsForLookup = (availableDoors) => {
  const filterData = availableDoors.map((door) => ({ value: `acb_door|||${door.id}`, label: `${door.name} (${door.board_name})` }));
  return filterData;
};

export const composeCompanySecurityAdminForLookup = (admins) => {
  const securityForLookUp = [];
  if (!admins) return null;

  admins.forEach((item) => {

    if (!item) return;

    if (item === 'security_empty') {
      securityForLookUp.push({ value: item, label: 'Without security' });
      return;
    }
    if (item === 'only_security') {
      securityForLookUp.push({ value: item, label: 'All with security names' });
      return;
    }

    if (item.includes('+')) {
      item.split('+').join('%2B');
    }
    securityForLookUp[item] = item;
    securityForLookUp.push({ value: item, label: item });
  });
  return securityForLookUp;
};

export const composeTenantsForLookup = (availableTenants) => {
  const defaultItems = [{
    value: 'members_empty',
    label: 'Without member',
  },
  {
    value: 'only_members',
    label: 'All with members names',
  }];

  const filterData = availableTenants.map((tenant) => ({ value: tenant.id, label: `${tenant.first_name} ${tenant.last_name}` }));
  return [...filterData, ...defaultItems];
};

export const composeEventsForLookup = (events) => {
  const eventsIds = Object.keys(events);

  return eventsIds.map((id) => ({ value: id, label: events[id] }));
};

export const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const composeEventsChain = (events) => events.map((evt, i) => ((events.length === i + 1) ? evt.type : `${evt.type} - `));

export const removePlusFromDialCode = (dialCode) => (dialCode.includes('+') ? dialCode.slice(1) : dialCode);

export const getUpdatedFilterData = (result, columns, columnIndex) => {
  const localColumns = columns;

  const currentFilterData = localColumns[columnIndex].filterData || [];
  const newFilterData = result.names && result.names.length > 0
    ? composeCompanySecurityAdminForLookup(result.names)
    : [];
  let isFilterDataChanged = false;
  const isEqualLength = currentFilterData.length === newFilterData.length;

  if (isEqualLength) {
    isFilterDataChanged = !currentFilterData.every((current) => newFilterData.some((newItem) => newItem.value === current.value && newItem.label === current.label));
  } else {
    isFilterDataChanged = true;
  }

  if (isFilterDataChanged) {
    if (newFilterData.length === 0) {
      localColumns[columnIndex].filtering = false;
    } else {
      localColumns[columnIndex].filtering = true;
      localColumns[columnIndex].filterData = newFilterData;
    }
    return localColumns;
  }
  return null;

};
