import {
  FETCH_COMPANIES,
  FETCH_ALL_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANY_SUCCESS,
  FETCH_ALL_COMPANIES_SUCCESS,
  FETCH_COMPANY,
  SHOW_COMPANY_MESSAGE,
  HIDE_COMPANY_MESSAGE,
  ON_SHOW_COMPANY_LOADER,
  ON_HIDE_COMPANY_LOADER,
  SWITCH_COMPANY,
  UPDATE_CURRENT_COMPANY,
} from '../ActionTypes';

export const fetchCompanies = ({ page = 1, companyIdFromUrl, location }) => ({
  type: FETCH_COMPANIES,
  payload: { page, companyIdFromUrl, location },
});

export const fetchAllCompanies = ({
  all = true, companyIdFromUrl, currentCompanyID, location,
}) => ({
  type: FETCH_ALL_COMPANIES,
  payload: {
    all, companyIdFromUrl, currentCompanyID, location,
  },

});

export const fetchCompany = (companyId) => ({
  type: FETCH_COMPANY,
  payload: { companyId },
});

export const fetchCompanySuccess = (company) => ({
  type: FETCH_COMPANY_SUCCESS,
  payload: { company },
});

export const fetchCompaniesSuccess = (companies, companyIdFromUrl) => ({
  type: FETCH_COMPANIES_SUCCESS,
  payload: { companies, companyIdFromUrl },
});

export const fetchAllCompaniesSuccess = (allCompanies, companyIdFromUrl, currentCompanyID) => ({
  type: FETCH_ALL_COMPANIES_SUCCESS,
  payload: { allCompanies, companyIdFromUrl, currentCompanyID },
});

export const showCompanyMessage = (message) => ({
  type: SHOW_COMPANY_MESSAGE,
  payload: message,
});

export const hideCompanyMessage = () => ({
  type: HIDE_COMPANY_MESSAGE,
});

export const showCompanyLoader = () => ({
  type: ON_SHOW_COMPANY_LOADER,
});

export const hideCompanyLoader = () => ({
  type: ON_HIDE_COMPANY_LOADER,
});

export const switchCompany = (company) => ({
  type: SWITCH_COMPANY,
  payload: company,
});

export const updateCurrentCompany = (companyData) => ({
  type: UPDATE_CURRENT_COMPANY,
  payload: companyData,
});
