import React from 'react';
import {
  FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export default class LBPasswordInput extends React.Component {
  state = { type: 'password' };

  toggleType() {
    this.setState((prevState) => ({ type: prevState.type === 'password' ? 'text' : 'password' }));
  }

  render() {
    const { type } = this.state;
    const {
      id, label, error, helperText, fullWidth, margin, className, ...other
    } = this.props;
    return (
      <FormControl margin={margin} fullWidth={fullWidth} className={className} error={error}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Input
          {...other}
          id={id}
          type={type}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.toggleType.bind(this)}
              >
                {type === 'text' ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }
}
