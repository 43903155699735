import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { NotificationManager } from 'react-notifications';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { hideMessage, showAuthLoader, userResetPassword } from 'store/actions/Auth';
import s from './ForgotPassword.module.css';
import LangSwitch from '../../components/LangSwitch/LangSwitch';
import { ForgotPasswordSchema } from '../validationSchemas';
import { Form } from './form';
import CompanyLogo from '../../components/CompanyLogo/CompanyLogo';

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = { email: '' };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
  }

  submitValues = ({ email }) => {
    this.props.showAuthLoader();
    this.props.userResetPassword({ email });
  };

  render() {
    const { email } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    const values = { email };

    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <CompanyLogo big />
          </div>

          <div className="app-login-content">
            <div className={s.langSwitch}>
              <LangSwitch shouldSyncWithBackend={false} />
            </div>
            <div className="app-login-header mb-4">
              <h1><IntlMessages id="appModule.resetPasswordHeader" /></h1>
            </div>

            <div className="app-login-header mb-4">
              <h5><IntlMessages id="appModule.resetPasswordText" /></h5>
            </div>

            <div className="app-login-form">
              <Formik
                render={(props) => <Form {...props} />}
                initialValues={values}
                validationSchema={ForgotPasswordSchema}
                onSubmit={this.submitValues}
              />
            </div>
          </div>

        </div>
        {
          loader
          && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )
        }
        {showMessage && NotificationManager.error(alertMessage)}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const {
    loader, alertMessage, showMessage, authUser, infoMessage,
  } = auth;
  return {
    loader, alertMessage, showMessage, authUser, infoMessage,
  };
};

export default connect(mapStateToProps, {
  userResetPassword,
  hideMessage,
  showAuthLoader,
})(ForgotPassword);
