import {
  FETCH_ALL_INTEGRATIONS_SUCCESS,
  UPDATE_INTEGRATIONS_LIST,
  UPDATE_INTEGRATION_CONFIGURATIONS,
  RESET_INTEGRATIONS_DATA,
} from '../ActionTypes';

const initialIntegrations = {
  integrationsList: {},
  integrationsConfigurations: {},
};

const integrations = (state = initialIntegrations, action) => {
  switch (action.type) {
    case FETCH_ALL_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        integrationsList: action.payload,
      };
    case UPDATE_INTEGRATIONS_LIST:
      return {
        ...state,
        integrationsList: action.payload,
      };
    case UPDATE_INTEGRATION_CONFIGURATIONS:
      return {
        ...state,
        integrationsConfigurations: { ...state.integrationsConfigurations, ...action.payload },
      };
    case RESET_INTEGRATIONS_DATA:
      return initialIntegrations;
    default:
      return state;
  }
};

export default integrations;
